import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { globalData } from './config';
import router from '@/router/index';
import { Toast } from 'vant';
import {setPushTags} from "@/utils/appUtil";
// 创建axios实例
const service = axios.create({

  baseURL: globalData.domainUrl,
  // 设置默认请求头，使post请求发送的是formdata格式数据// axios的header默认的Content-Type好像是'application/json;charset=UTF-8',我的项目都是用json格式传输，如果需要更改的话，可以用这种方式修改
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    'Content-Type': 'application/json;charset=utf-8',
    "Access-Control-Allow-Methods": 'POST,OPTIONS,GET,PATCH,DELETE',
  },
  // withCredentials: true // 允许携带cookie
  timeout: 1.5  * 60 * 1000,
});
const serviceUpload = axios.create({
  baseURL: globalData.domainUrl,
  timeout: 2  * 60 * 1000,
});

// service.defaults.baseURL =  globalData.domainUrl;

const codeMessage: any = {
  200: 'success',
};



// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // Add X-Token header to every request, you can add other custom headers here
    if (localStorage.getItem('token')) {
      config.headers['token'] = localStorage.getItem('token');
      // config.headers['token'] = "234232ewssdfsdfsdfsfsdfsdfsdfs3423423423sdfsdfsdf";
    }
    dealLan(config)
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

// 处理语言
function dealLan(config:AxiosRequestConfig) {
  if (config.method == "get"){
    let i = config.params
    if (i!=null){
      i = Object.assign(i,{
        lang: globalData.lan
      })
    }else{
      i = {
        lang: globalData.lan
      }
    }
  }else{
    config.url += ("?lang="+ globalData.lan)
  }
}

// 响应拦截器
async function filter<T>(response: AxiosResponse<T>): Promise<T> {
  const res = response.data as any;
  if (response && response.status >= 200 && response.status < 300) {
    const d: any = response.data
    if (response && d.code == 401) { //判断token是否失效
      localStorage.removeItem('token')
      localStorage.removeItem('userInfo')
      localStorage.removeItem('loginTime')
      setPushTags([]);
      router.replace({
        path: '/login'
      })
      // Toast(d.msg);
    } else {
      if (response && d.code > 0) {
        Toast(d.msg);
      }
    }

    return response.data;

  }
  // 请求的状态错误
  const errortext = codeMessage[response.status] || response.statusText;
  const error = new Error(errortext);
  error.name = response.status.toString();
  return Promise.reject(error);
}



export default {
  async post<T = any>(url: string, data?: any) {
    try { //  post
      console.log(url + ' post request ', data);
      const response = await service.request<T>({
        method: 'post',
        url,
        data,
        headers: { 'Content-Type': 'application/json;charset=utf-8' },
      });

      console.log(url + ' post response', response);


      const final = await filter<T>(response);
      return final;

    } catch (error: any) {
      console.log(url + ' post response error.message', error.message);
      // 错误返回空对象
      return {} as T;
    }
  },
  async post2<T = any>(url: string, params?: any) {
    try { //  post
      console.log(url + ' post request ', params);
      const response = await service.request<T>({
        method: 'post',
        url,
        params,
        headers: { 'Content-Type': 'application/json;charset=utf-8' },
      });

      console.log(url + ' post response', response);


      const final = await filter<T>(response);
      return final;

    } catch (error: any) {
      console.log(url + ' post response error.message', error.message);
      // 错误返回空对象
      return {} as T;
    }
  },
  async mimePost<T = any>(url: string, files: File[]) {
    try { //  post
      console.log(url + ' post request ', files);
      const form = new FormData();
      files.forEach(element => {
        form.append("files", element, element.name);
      });
      const response = await service.request<T>({
        method: 'post',
        url,
        data: form,
        headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
      });
      console.log(url + ' post response', response);
      const final = await filter<T>(response);
      return final;

    } catch (error: any) {
      console.log(url + ' post response error.message', error.message);
      // 错误返回空对象
      return {} as T;
    }
  },
  async mimePost2<T = any>(url: string, file: File) {
    try { //  post
      console.log(url + ' post request ', File);
      const form = new FormData();
      form.append("file", file, file.name);
      const response = await service.request<T>({
        method: 'post',
        url,
        data: form,
        headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
      });
      console.log(url + ' post response', response);
      const final = await filter<T>(response);
      return final;

    } catch (error: any) {
      console.log(url + ' post response error.message', error.message);
      // 错误返回空对象
      return {} as T;
    }
  },
  async get<T = any>(url: string, params?: any) { // get
    try {
      console.log(url + ' get request ', params);
      const response = await service.request<T>({
        method: 'get',
        url,
        params,
      });
      console.log(url + ' get response', response);
      const final = await filter<T>(response);
      return final;
    } catch (error: any) {
      console.log(url + ' get response error.message', error.message);
      // 错误返回空对象
      return {} as T;
    }
  },
  async delete<T = any>(url: string, data?: any) {
    try { //  post
      console.log(url + ' delete request ', data);
      const response = await service.request<T>({
        method: 'delete',
        url,
        data,
        headers: { 'Content-Type': 'application/json;charset=utf-8' }
      });

      console.log(url + ' delete response', response);


      const final = await filter<T>(response);
      return final;

    } catch (error: any) {
      console.log(url + ' delete response error.message', error.message);
      // 错误返回空对象
      return {} as T;
    }
  },
  async patch<T = any>(url: string, data?: any) {
    try { //  post
      console.log(url + ' patch request ', data);


      // const response = await axios.patch(globalData.domainUrl + url, data);
      const response = await service.request<T>({
        method: 'patch',
        url,
        data,
      });

      console.log(url + ' patch response', response);


      const final = await filter<T>(response);
      return final;

    } catch (error: any) {
      console.log(url + ' patch response error.message', error.message);
      // 错误返回空对象
      return {} as T;
    }
  },
};

