import { jsonParse } from "./utils";
import { User,getUserInfo, GetUserInfoRes, UserInfo } from "@/api/user";
import { Toast } from "vant";
export interface WebAppInterface {
    toVideo(devId: any) : any;
    toRecordActivity(devId: any) : any;
    setPushTags(username: string, tags: any) : any;
    loginRobot(username: string, password: string, url: string) : any;
    loginRobotNew(username: string, password: string, userType: number, url: string) : any;
    appUpgrade():any;
    getAppVersion():any;
    toqrscan():any
    stopService():any;
    getPushId(): string;
    isNewVersion(): boolean;
}
declare const window: Window & { webkit: any };
declare const app: WebAppInterface;
export function toPlayBack (ip_camera_id: string){
  app.toRecordActivity(ip_camera_id)
}
export function toVideo(ip_camera_id: string) {
  app.toVideo(ip_camera_id)// a96109eff8655e9e   7b5a0300edddb801
}
export function setPushTags(tags: string[]) {
  const username = localStorage.getItem("account");
  // if (username) {
  //   mobpush.setPushTags(username, tags)// a96109eff8655e9e   7b5a0300edddb801
  // } else {
  //   console.log("username setPushTags:", username)
  // }
  try {
    if (username) {
      if(tags.length > 100) {
        tags = tags.slice(0, 100)
      }
      app.setPushTags(username, tags)// a96109eff8655e9e   7b5a0300edddb801
    } else {
      console.log("username setPushTags:", username)
    }
  } catch (error) {
    try {
      const msg = {'username':username, tags:tags}
      const jsonArryStr = JSON.stringify(msg)
      window.webkit.messageHandlers.mobpush.postMessage(jsonArryStr)
      console.log("发送消息给IOS:", jsonArryStr);
    } catch (error) {
      console.log("IOS setPushTags postMessage:", error)
    }
  }
}
export function getPushId() {
    let pushid:string = ""
    try {
      pushid = app.getPushId()
    } catch (error) {
      console.log("IOS setPushTags postMessage:", error)
    }
    return pushid
}
//  PERMISSION_ADMIN = 1
// uint8 PERMISSION_USER = 0
// uint8 PERMISSION_SUPER_ADMIN = 2
// metabots = 3

export async function loginRobotOld(url: string, companyCode: string) {
    try {
      const userInfoStr: string | null = localStorage.getItem("userInfo");
      if (userInfoStr == null) {
        return;
      }
      const userInfo: User = JSON.parse(userInfoStr);
      console.log("userInfo.userType:" + userInfo.userType)
      if (userInfo.userType === 1) {
        console.log("userInfo.userType 11111111111")
        app.loginRobot("remote_admin", "movexbot", url);
      } else if (userInfo.userType === 2) {
        console.log("userInfo.userType 22222222222")
        if (companyCode == 'MX') {
          console.log("userInfo.userType 33333333")
          app.loginRobot("metabots", "147258", url);
        } else {
          app.loginRobot("remote_super_admin", "movexbot", url);
        }
      } else if (userInfo.userType === 3) {
        console.log("userInfo.userType 33333333")
        app.loginRobot("metabots", "147258", url);
      } else {
        console.log("userInfo.userType else")
        app.loginRobot("remote_user", "movexbot", url);
      }
    } catch (error) {
      console.log("loginRobot error");
    }
}
export async function loginRobot(url: string, companyCode: string) {
    if (typeof app.loginRobotNew === 'function') {
      loginRobotNew(url, companyCode);
    } else if (typeof app.loginRobot === 'function') {
      loginRobotOld(url, companyCode);
    } else {
      console.error("Login methods are not available.");
    }
}
export async function loginRobotNew(url: string, companyCode: string) {
  try {
    const userInfoStr: string | null = localStorage.getItem("userInfo");
    if (userInfoStr == null) {
      return;
    }
    const userInfo: User = JSON.parse(userInfoStr);
    console.log("userInfo.userType:" + userInfo.userType)
    const username = userInfo.username
    if (userInfo.userType === 1) {
      app.loginRobotNew(username, "movexbot", 3, url);
    } else if (userInfo.userType === 2) {
      console.log("userInfo.userType 22222222222")
      if (companyCode == 'MX') {
        console.log("userInfo.userType 33333333")
        app.loginRobotNew(username, "movexbot", 5, url);
      } else {
        app.loginRobotNew(username, "movexbot", 4, url);
      }
    } else if (userInfo.userType === 3) {
      console.log("userInfo.userType 33333333")
      app.loginRobotNew(username, "movexbot", 5, url);
    } else {
      console.log("userInfo.userType else")
      app.loginRobotNew(username, "movexbot", 2, url);
    }
  } catch (error) {
    console.log("loginRobot error");
  }
}
export function stopService(){
  try {
    app.stopService();
  } catch (error) {
    console.log("stopService error");
  }
}
export function toqrscan(tip:any){
  try {
    app.toqrscan();
  } catch (error) {
    console.log("toqrsacn error");
    Toast(tip)
  }
}
export function appUpgrade(){
  try {
    app.appUpgrade();
  } catch (error) {
    console.log("appUpgrade error");
  }
}
export function getAppVersion() : string{
  try {
    return app.getAppVersion();
  } catch (error) {
    return "1.0"
  }
}
export function isNewVersion() : boolean {
  try {
    return app.isNewVersion();
  } catch (error) {
    console.log("isNewVersion error:", error)
    return false
  }
}
export function isiOS(): boolean {
  const u = navigator.userAgent;
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  return isiOS;
}
